import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Prabhupada = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div>
             <div>
        <h1 className="text-center  font-semibold text-2xl text-black my-5 underline">
          প্রভুপাদ {' '} ইতিহাস
        </h1>

        <div className="max-w-3xl  mx-auto max-h-[504px] mb-2 ">
          <iframe
            className="w-full"
            // width="560"
            height="431"
            src="https://www.youtube.com/embed/vUyQPxOmGpo?si=p6-ZoYKIZnocYjvW"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="max-w-3xl  mx-auto max-h-[504px] mb-2 ">
          <iframe
            className="w-full"
            // width="560"
            height="431"
            src="https://www.youtube.com/embed/sCF_KVDg3V0?si=kp8HY7ccjZe0ZqTo"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="max-w-3xl  mx-auto max-h-[504px] mb-2 ">
          <iframe
            className="w-full"
            // width="560"
            height="431"
            src="https://www.youtube.com/embed/UsoczeYeaY8?si=ns52k7Hi6rXWSMfD"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
        </div>
    );
};

export default Prabhupada;