import React, { useEffect } from "react";

import img from "../../../assets/image/baneer/baneer2.jpg";
import { useLocation } from "react-router-dom";

const PrivetPlolicy = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="px-3">
      <div>
        <img src={img} alt=""  className="mx-auto w-fit my-5"/>
      </div>
      {/* main worke stat */}

      <p
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginVertical: 10,
          color: "black",
        }}
      >
        Privacy Policy
      </p>
      <p style={{ color: "black" }}>
        This privacy policy applies between you, the User of this Website and
        Mayapur Sambad, the owner and provider of this Website. Mayapur Sambad takes
        the privacy of your information very seriously. This privacy policy
        applies to our use of any and all Data collected by us or provided by
        you in relation to your use of the Website.
      </p>

      <p
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginVertical: 10,
          color: "black",
        }}
      >
        Data collected
      </p>

      <p style={{ fontWeight: "500", color: "black" }}>
        1. We may collect the following Data, which includes personal Data, from
        you:
      </p>

      <p style={{ color: "black" }}>Name:</p>
      <p style={{ color: "black" }}>
        contact Information such as email address;
      </p>
      <p style={{ color: "black" }}>
        in each case, in accordance with this privacy policy.
      </p>
      {/*  2*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Data that is given to us by you
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. Mayapur Sambad will collect your Data in a number of ways, for
          example:
        </p>

        <p style={{ color: "black" }}>
          when you contact us through the Website contact message, by e-mail or
          through any other means;
        </p>
        <p style={{ color: "black" }}>
          when you register with us and set up an account to chat online or
          receive our newsletter;
        </p>
        <p style={{ color: "black" }}>
          when you make payments to us, through this Website or otherwise;
        </p>
        <p style={{ color: "black" }}>when you enter a website competition;</p>
      </div>
      {/*  3*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Our use of Data
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. Any or all of the above Data may be required by us from time to
          time in order to provide you with the best possible service and
          experience when using our Website. Specifically, Data may be used by
          us for the following reasons:
        </p>

        <p style={{ color: "black" }}>
          transmission by email of devotional materials and opportunities that
          may be of interest to you;
        </p>
        <p style={{ color: "black" }}>online chat;</p>
        <p style={{ color: "black" }}>
          We may use your Data for the above purposes if we think it may be
          useful for you. If you are not satisfied with this, you have the right
          to object.
        </p>
      </div>
      {/*  4*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Keeping Data secure
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. We will use technical and organisational measures to safeguard your
          Data, for example:
        </p>

        <p style={{ color: "black" }}>Name:</p>
        <p style={{ color: "black" }}>
          contact Information such as email address;
        </p>
        <p style={{ color: "black" }}>
          in each case, in accordance with this privacy policy.
        </p>
      </div>
      {/*  5*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Data collected
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. We may collect the following Data, which includes personal Data,
          from you:
        </p>

        <p style={{ color: "black" }}>we store your Data on secure servers.</p>
        <p style={{ color: "black" }}>
          Technical and organisational measures include measures to deal with
          any suspected data breach. If you suspect any misuse or loss or
          unauthorised access to your Data, please let us know immediately by
          contacting us via this e-mail address: info@mayapur.tv.
        </p>
      </div>
      {/*  6*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Data retention
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. Unless a longer retention period is required or permitted by law,
          we will only hold your Data on our systems for the period necessary to
          fulfil the purposes outlined in this privacy policy or until you
          request that the Data be deleted.
        </p>

        <p style={{ color: "black" }}>
          It is important that the Data we hold about you is accurate and
          current. Please keep us informed if your Data changes during the
          period for which we hold it.
        </p>
      </div>
      {/*  7*/}
      <div style={{ marginBottom: 25 }}>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Changes to this privacy policy
        </p>

        <p style={{ fontWeight: "500", color: "black" }}>
          1. Mayapur Sambad reserves the right to change this privacy policy as we
          may deem necessary from time to time or as may be required by law. Any
          changes will be immediately posted on the Website and you are deemed
          to have accepted the terms of the privacy policy on your first use of
          the Website following the alterations.You may contact Mayapur Sambad by
          email at info@mayapur.tv.
        </p>
      </div>
    </div>
  );
};

export default PrivetPlolicy;
