import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useLocation } from "react-router-dom";

const AllTemples = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const temple1 = [
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/habibpur/habibpur7.jpg",
    },
  ];

  const temple2 = [
    {
      url: "https://mayapursambad.com/image/allTemples/siliguri/siliguri1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/siliguri/siliguri2.jpg",
    },
  ];

  const temple3 = [
    {
      url: "https://mayapursambad.com/image/allTemples/durgapur/durgapur1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/durgapur/durgapur2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/durgapur/durgapur3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/durgapur/durgapur4.jpg",
    },
  ];

  const temple4 = [
    {
      url: "https://mayapursambad.com/image/allTemples/kolkata/kolkata1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/kolkata/kolkata2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/kolkata/kolkata3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/kolkata/kolkata4.jpg",
    },
  ];

  const temple5 = [
    {
      url: "https://mayapursambad.com/image/allTemples/haridaspur/haridaspur1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/haridaspur/haridaspur2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/haridaspur/haridaspur3.jpg",
    },
  ];

  const temple6 = [
    {
      url: "https://mayapursambad.com/image/allTemples/guwahati/guwahati1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/guwahati/guwahati2.jpg",
    },
  ];

  const temple7 = [
    {
      url: "https://mayapursambad.com/image/allTemples/agartala/agartala1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/agartala/agartala2.jpg",
    },
  ];

  const temple8 = [
    {
      url: "https://mayapursambad.com/image/allTemples/macheda/macheda1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/macheda/macheda2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/macheda/macheda3.jpg",
    },
  ];

  const temple9 = [
    {
      url: "https://mayapursambad.com/image/allTemples/gangasagar/gangasagar1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/gangasagar/gangasagar2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/gangasagar/gangasagar3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/gangasagar/gangasagar4.jpg",
    },
  ];

  const temple10 = [
    {
      url: "https://mayapursambad.com/image/allTemples/malda/malda1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/malda/malda2.jpg",
    },
  ];

  const temple11 = [
    {
      url: "https://mayapursambad.com/image/allTemples/panihati/panihati1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/panihati/panihati2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/panihati/panihati3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/panihati/panihati4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/panihati/panihati5.jpg",
    },
  ];

  const temple12 = [
    {
      url: "https://mayapursambad.com/image/allTemples/ekachakra/ekachakra1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/ekachakra/ekachakra2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/ekachakra/ekachakra3.jpg",
    },
  ];
  const temple13 = [
    {
      url: "https://mayapursambad.com/image/allTemples/pakur/pakur1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/pakur/pakur2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/pakur/pakur3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/pakur/pakur4.jpg",
    },
  ];
  const temple14 = [
    {
      url: "https://mayapursambad.com/image/allTemples/chatra/chatra1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/allTemples/chatra/chatra2.jpg",
    },
  ];
  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/habibpur/habibpur.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: ইসকন হাবিবপুর, রানাঘাট, নদীয়া, ৭৪১৪০৩, পশ্চিমবঙ্গ ফোন: {""}
            +৯১ ৯৭৩২১৮৫৫৫৩ ওয়েবসাইট:{" "}
            <a href="https://www.iskconhabibpur.com" target="_blank">
              www.iskconhabibpur.com
            </a>{" "}
            ইমেল: iskconhabibpurofficial@gmail.com রবিবাসরীয় গীতা ক্লাস: সকাল
            ১১:০০টা সর্বসাধারণের জন্য বিকেল ৪:০০টা যুবকদের জন্য দর্শনীয়: গোশালা,
            গুরুকূল‌, নৌকাভ্রমন ২০.০০ টাকা প্রসাদম: দুপুর দুপুর ১২:০০ টার মধ্যে
            শ্রী শ্রীজগন্নাথের রাজভোগ প্রসাদ কুপন সংগ্রহ করুণ
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/siliguri/siliguri.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            গেস্ট হাউস: শ্রীপাদ রাম অবতার নিতাই দাস: +৯১ ৯৪০০৮৬৫১০৪ গোবিন্দ
            রেস্টুরেন্ট খোলা/বন্ধের সময়: সকাল ১১:০০টা - দুপুর ২:০০টা, বিকাল
            ৪:০০টা - রাত ৮:০০টা টিকিট: ট্রেন, ফ্লাইট নবদ্বীপ ভ্রমণ: নবদ্বীপ
            ধামের বিভিন্ন স্থান দর্শন যোগাযোগ: শ্রীপাদ সুবিজয় গৌর দাস: +৯১
            ৯২৩১৬৮৪৭৫৯
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/durgapur/durgapur.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান:বি-২/২৯, নেতাজি সুভাষ রোড, ধোবিঘাট- এ জোন, দুর্গাপুর,
            পশ্চিমবঙ্গ, ৭১৩২০৪ মন্দির খোলা/বন্ধের সময়: বিকেল ৪:৩০মি - রাত ৮:৪৫মি{" "}
            যোগাযোগ: ফোন: +৯১ ৯৪৩৪৫৫১৯০৯
          </h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/kolkata/kolkata.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান:থ্রী/সি আলবার্ট রোড, বিড়লা হাই স্কুলের কাছে, মিন্টো পার্ক,
            কলকাতা – ৭০০০১৭ গেস্টহাউস: বুকিং এর সময় - সকাল ১১:০০টা - বিকাল
            ৫:০০টা শুধুমাত্র হোয়াটসঅ্যাপের মাধ্যমে বুকিং - +৯১ ৮৩৩৪৯৪৯৯৯৭
            যোগাযোগ - ০৩৩ ৪০৪০৩৩০০{"\n"}
            ইমেইল: guesthouse.iskconkolkata@gmail.com যোগাযোগ: ০৩৩ ৪০৪০৩৩০০{" "}
            ইমেইল: info@iskconkolkata.com
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/haridaspur/haridaspur.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: পি.ও. ছায়াঘরিয়া, বনগাঁ, ২৪-পরগনা, হরিদাসপুর, পশ্চিমবঙ্গ,
            ভারত - ৭৪৩৭০৪ যোগাযোগ: ফোন: +৯১ ০৩২১৫ ৫৭৮৫৬
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/guwahati/gauhati.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: উলুবাড়ি চারালী, দক্ষিণ সারানিয়া, গুয়াহাটি, আসাম, ভারত –
            ৭৮১০০৭ যোগাযোগ: +৯১ ০৩৬১ ২৫২৫৯৬৩, +৯১ ০৩৬১ ২৫২৭৮৯০, +৯১ ৯৪৩৫০৪৮১১০,
            +৯১ ৯৮৫৯৯৭৭১৭৩ ইমেল: iskcon.guwahati@pamho.net
          </h1>
        </div>
      </div>

      {/*777777777777777777777777777777777777777777777*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple7.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/agartala/agartala.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: আসাম-আগরতলা রোড, বনমালিপুর, আগরতলা, ত্রিপুরা, ভারত – ৭৯৯০০১{" "}
            যোগাযোগ: +৯১ ০৩৮১ ২৩২৭০৫৩, +৯১ ০৩৮১ ২২০৪৫১৮, +৯১ ৯৪৩৬১৬৭০৪৫{" "}
            ওয়েবসাইট:{" "}
            <a href="https://www.iskconagartala.org" target="_blank">
              www.iskconagartala.org
            </a>{" "}
            ইমেইল: premadatadas@rediffmail.com
          </h1>
        </div>
      </div>

      {/*888888888888888888888888888888888888888888*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple8.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/macheda/mecheda.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            মেচেদা মন্দির: ছোট গাড়ী, বাস দর্শন: গঙ্গাসাগর, তারাপীঠ, একচক্রা,
            হাজারদুয়ারি, শান্তিনিকেতন টিকিট: ট্রেন, ফ্লাইট নবদ্বীপ ভ্রমণ:
            নবদ্বীপ ধামের বিভিন্ন স্থান দর্শন যোগাযোগ: +৯১ ৯৫৯৩৪৭৯৯৯০, +৯১
            ৯৫৯৩৯৫৯৯৯০ ইমেইল: travels@gmail.com
          </h1>
        </div>
      </div>

      {/*99999999999999999999999999999999999999999999*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple9.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/gangasagar/gangasagar.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: হরে কৃষ্ণ ল্যান্ড; রোড নং 5; হেলিপ্যাড এবং কে-২ বাস
            টার্মিনালের কাছে; গ্রাম, ডাক ও থানা: সাগর; দক্ষিণ ২৪-পরগণা জেলা,
            পশ্চিমবঙ্গ, ভারত। পিন কোড: ৭৪৩৩৭৩ যোগাযোগ: +৯১ ৯৭৩৫৩৩৩৫১১ ইমেইল:
            iskcongs@gmail.com
          </h1>
        </div>
      </div>

      {/*101010101010101010101010101010*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple10.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/malda/malda.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: গোলাপট্টি, নেতাজি সুভাষ রোড, পশ্চিমবঙ্গ -৭৩২১০১ যোগাযোগ: +৯১
            ৯৮৭৯৯৪৯৯০ ইমেইল: info@iskconmalda.com
          </h1>
        </div>
      </div>

      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple11.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/panihati/panihati.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: 37, গৌরাঙ্গ ঘাট রোড, পানিহাটি ফেরি ঘাটের কাছে, উত্তর 24 পরগনা{" "}
            আন্যান্য সুবিধা: প্রসাদের অর্ডার নেওয়া হয়, গঙ্গাতে নৌকা ভ্রমনের
            সুবিধা আছে, রাঘব ভবন দর্শনের সুযোগ আছে। যোগাযোগ: {""}+৯১ ৯০০৭০৫০৪৮৫{" "}
            ইমেইল: {""}info@iskconmalda.com
          </h1>
        </div>
      </div>

      {/*122222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple12.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/ekachakra/ekachakra.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: একচক্র, গ্রাম এবং পোস্ট বীরচন্দ্রপুর, বীরভূম জেলা, পশ্চিমবঙ্গ
            731245 যোগাযোগ: +৯১ (৩৪৬১)২২০২৯৬
          </h1>
        </div>
      </div>

      {/*1333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple13.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/pakur/pakur.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: কালী ভাষা পুকুর, P.O. এবং জেলা - পাকুড়, ঝাড়খন্ড - ৮১৬১০৭{" "}
            ফোন:+৯১ ৮০৮৪৮১১০৮৮ ইমেইল:gopinathgopaldas@gmail.com গোশালা:৭ টি গরু,
            যোগাযোগ: +৯১ ৯৮০০৮৬৫০৯৮ প্রসাদ:সকাল ৯:০০ টা, দুপুর ১:০০ টা, রাত ৮:৩০
            মি কাছাকাছি দর্শনীয় স্থান:কানাইনাটশালা, মন্দারপর্বত, মতি ঝর্না,
            একচক্র ধাম
          </h1>
        </div>
      </div>

      {/*1414141414141414*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple14.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/allTemples/chatra/chatra.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: সুভাষপল্লী, পোষ্টঅফিসঃ ঘোষপুর, ছাত্রা(মসলন্দপুর), জেলাঃ উত্তর
            ২৪ পরগণা, পশ্চিমবঙ্গ - ৭৪৩২৮৯ ফোন: +৯১ ৭৫৯৫০৭২৯০৬
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AllTemples;
