import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useLocation } from "react-router-dom";

const Join = () => {
  
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const temple1 = [
    {
      url: "https://mayapursambad.com/image/join/photo/ChatraSamaj1.jpg",
    },
  ];

  const temple2 = [
    {
      url: "https://mayapursambad.com/image/join/photo/NamhattaPreaching1.jpg",
    },
  ];

  const temple3 = [
    {
      url: "https://mayapursambad.com/image/join/photo/gitapreaching1.jpg",
    },
  ];

  const temple4 = [
    {
      url: "https://mayapursambad.com/image/join/BhaktiVriksha/BhaktiVriksha1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/join/BhaktiVriksha/BhaktiVriksha2.jpg",
    },
  ];

  const temple5 = [
    {
      url: "https://mayapursambad.com/image/join/TribalPreaching/TribalPreaching1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/join/TribalPreaching/TribalPreaching2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/join/TribalPreaching/TribalPreaching3.jpg",
    },
  ];

  const temple6 = [
    {
      url: "https://mayapursambad.com/image/join/photo/ndp1.jpg",
    },
  ];
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/join/logo/namhattapreaching.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            ইসকন মায়াপুর মন্দির থেকে ব্রহ্মচারী ভক্তদের দ্বারা পরিচালিত এই
            প্রচার বিভাগটি। ছাত্র ও যুব সমাজকে আধ্যাত্মিক জ্ঞানের বিকাশ এবং
            সুচরিত্র গঠনের জন্য প্রতি সপ্তাহে নিজ নিজ এলাকায় নিয়মিত ক্লাশ, ভ্রমন
            ও সেমিনারের ব্যাবস্থা করা হয়। স্থান: শ্রুতি ভবন (গোশালার কাছে){" "}
            যোগাযোগ :নদীয়া ও মুর্শিদাবাদ - শ্রীপাদ শ্রীনিধি চৈতন্য দাস - টেলি:{" "}
            +৯১ ৬২৯৪০৩৯১৪৬ , মেদিনীপুর, ঝাড়গ্রাম এবং বাঁকুড়া - শ্রীপাদ
            যশোদাপুত্র দাস - টেলি: +৯১ ৯০৪৬৬২৯১০২ , নদীয়া এবং বর্ধমান - শ্রীপাদ
            লীলাময় দামোদর দাস - টেলি: +৯১ ৯০৪৬৬২৯১০২ | অফিস - +৯১ ৭৫৭৫০১০৪০০,
            +৯১ ৯৪৩৪৫৫১৭২১ দূরত্ব: ৫০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/join/logo/chatrasamajpreaching.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            যোগাযোগ করুন: বিশ্বজিৎ দাস (কো-রিজনাল ডাইরেক্টর) - +৯১ ৯৪৩৪৩১২৬৫২ ,{" "}
            পূর্ব,পশ্চিম-মেদিনীপুর, ঝাড়গ্রাম - +৯১ ৯৮৭৪৪৭৬৩৩৮ নদীয়া - +৯১
            ৭০০১১৪১২২৩ উত্তর ২৪ পরগনা - +৯১ ৯৪৩৩৩৫৬০৫৯ দক্ষিন ২৪ পরগনা - +৯১
            ৮৭৬৮১৪৮৬৩৭ কোলকাতা - +৯১ ৯৮৭৪৪৭৬৩৩৮ হাওড়া - +৯১ ৮৩৪৩০৯৯৪৩৫ হুগলী -{" "}
            +৯১ ৮২৯৩৪০২৯৩৯ বাঁকুড়া, পুরুলিয়া, ঝাড়খণ্ড - +৯১ ৯০৩৮০০৫৩৫৬{" "}
            মুর্শিদাবাদ, বীরভূম - +৯১ ৮৯২৭০৮২০৪২ মালদা - +৯১ ৬২৯৫৫১৭৩৫৬ উত্তর
            দিনাজপুর - +৯১ ৮১৬৭০৪২৬২৪ দক্ষিন দিনাজপুর - +৯১ ৬২৯৪২৪৪৩৫৪{" "}
            জলপাইগুড়ি, দার্জেলিং - +৯১ ৯৪৭৫২১৯২২৭ বর্ধমান - +৯১ ৯৬০৯১৬৬৪২২{" "}
            কুচবিহার - +৯১ ৯৭৭৫৩৩২২৩৩ উপরের আসাম, ত্রিপুরা - +৯১ ০৭০০২৬২৯১৩৮{" "}
            আসাম - +৯১ ৭০৮৬৬৪৬০৩৭ উড়িশা (পূর্ব) - +৯১ ৯৪৩৭২৬৪৪৫৪
            {""}
            উড়িশা (পশ্চিম) - +৯১ ৯৫৫৬৬৫১৫৭৩ আসানসোল - +৯১ ৯৪৩৩৩২০৩১৪ রিসেপশন
            (মায়াপুর) - +৯১ ৭৫৪৮৯৬৯২৬৯
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/join/logo/gitapreaching.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            ক্লাস: আপনি যে কোন বয়সে ঘরে বসে গীতা, শ্রীমদ্ভাভাগবত পড়তে ও পরীক্ষা
            দিতে পারবেন{""}
            যোগাযোগ :কোলকাতা- +৯১ ৯৪৭৫৬৮৯৩৮১ মেদিনীপুর- +৯১ ৯৭৩৫২১২৩৫৮ নদীয়া -{" "}
            +৯১ ৭৬৯৯৩২৭৮৭৪ মায়াপুর অফিস - +৯১ ৯০৯১৪৯৮৩৯১ স্থান:গীতা ভবন (বংশী
            ভবনের পাশে)
          </h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/join/BhaktiVriksha/bhaktivriksha.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            ক্লাস: শ্রীচৈতন্য মহাপ্রভুর ধাম শ্রীধাম মায়াপুরের পার্শ্ববর্তী
            এলাকায় যেমন - স্বরূপগঞ্জ, কৃষ্ণনগর ও নবদ্বীপ ইত্যাদি স্থানগুলিতে
            সাপ্তাহিক ক্লাসের মাধ্যমে মহাপ্রভুর শিক্ষা দেওয়ার সুযোগ দেওয়া হয়{" "}
            যোগাযোগ :কৃষ্ণনগর -{"  "}
            ধুবুলিয়া - +৯১ ৮১৪৫৭৮৬৭৭৭ স্বরুপগঞ্জ - + ৯১ ৯৬০৯৩৫৬৮৭৫ স্থান:চক্র
            ভবন, রুম নং - ১২০ দূরত্ব:২০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/join/TribalPreaching/tribalpreaching.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            ভারতের বিভিন্ন স্থানের সকল বয়সের জনজাতিদের মধ্যে শ্রীল প্রভুপাদের
            শিক্ষা দেওয়ার সুযোগ দেওয়া হচ্ছে। ক্লাস: পুরুলিয়াঃ - বলদেব প্রাণ দাস:{" "}
            +91 9804487895 (কোচিং সেন্টার - ৪০টি) বাকুরাঃ +91 9434880000 (কোচিং
            সেন্টার - ২৫টি) মেদিনীপুরঃ +91 6294244354 (কোচিং সেন্টার - ৩০টি){" "}
            শিলিগুড়িঃ +91 8617440961 (কোচিং সেন্টার - ১৫টি) আসাম ও ত্রিপুরাঃ +91
            8658490726 ইমেইল : info@itct.org.in ওয়েবসাইট :{" "}
            <a href="https://www.indiatribalcare.org/" target="_blank" rel="noreferrer">
              www.indiatribalcare.org
            </a>{" "}
            রেজিস্টার অফিস :গীতা ভবন, শ্রীধাম মায়াপুর, নদিয়া, পশ্চিমবঙ্গ -
            741313 এডমিনিস্ট্রেটিভ অফিস :১৭৭, পূর্ব ষ্টেশন রোড, নিউ ব্যারাকপুর,
            কোলকাতা - 700131{" "}
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/join/logo/ndppreaching.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            যোগ্যতা: যে কোন ব্যক্তি (পুরুষ) ৩০ বছর বয়সের মধ্যে উচ্চ মাধ্যমিক
            সম্পর্ণ করার পর ইসকন মন্দিরে ব্রহ্মচারী ভক্ত হিসাবে যোগদান করতে
            পারেন। স্থান: চক্র ভবন, রুম নং - ১২৩/ডি যোগাযোগ : ০৩৪৭২ ২৪৫৩৮৫, +৯১
            ৯৪৩৪১৭৩৪৬৬ দূরত্ব : ২০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Join;
