import { RouterProvider } from 'react-router-dom';
import roueter from './Components/Router/Router';

function App() {
  return (
    <div  className="max-w-[1350px] mx-auto">
     <RouterProvider router={roueter}></RouterProvider>
    </div>
  );
}

export default App;
