import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Connection = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      {/* 1st */}
          <div className="xl:flex lg:flex md:flex  my-4 bg-white gap-5 shadow-xl  rounded-lg ">
          
        <img src="https://mayapursambad.com/image/Connection/BusStand1.jpg" alt="" className=" rounded-lg " />
              <div className="py-3 px-2">
                  
                      
          <img src="https://mayapursambad.com/image/Connection/mtk.png" alt=""  className=""/>
                  
          <p className="text-black text-xl"> 
            সময়সূচি: কোলকাতা থেকে মায়াপুর ক্যাম্পাসে বাসে আসতে হলে ধর্মতলা থেকে
            সকাল ৬ টায় ও দুপুর ৩ টায় বাস ছাড়ে। মায়াপুর ক্যাম্পাস থেকেও কোলকাতায়
            আসার জন্য একি সময়ে বাস ছাড়ে। মাধ্যম: আপনারা যে কোন বাসে মায়াপুর আসতে
            হলে বহরমপুর, মালদা বা উত্তরবঙ্গের বাসে করে ধুবুলিয়াতে (চৌগাছা) নামতে
            হবে। তারপর বাসে করে বা টোটো করে মায়াপুর ইসকন মন্দিরের মেনগেট বা
            নামহট্ট গেটে নামতে পারেন অথবা কৃষ্ণনগর থেকে অটো করে মায়াপুরঘাটে
            আসবেন নৌকা করে গঙ্গা পার হয়ে টোটো করে মায়াপুর মেনগেটে আসটে পারবেন।
            যোগাযোগ: ০৩৪৭২ ২৪৫৩৮৫, +৯১ ৯৪৩৪১৭৩৪৬৬
          </p>
        </div>
          </div>


        {/* 2nd */}
      <div className="xl:flex lg:flex md:flex  my-4 bg-white gap-5 shadow-xl  rounded-lg ">
        <img src="https://mayapursambad.com/image/Connection/KrishnagarStation1.jpg" alt="" className=" rounded-lg " />
        <div className="py-3 px-2">
          <img src="https://mayapursambad.com/image/Connection/krishnagar.png" alt="" />
          <p className="text-black text-xl"> 
            কিভাবে আসবেন: শিয়ালদা থেকে ট্রেনে করে মায়াপুর আসতে হলে কৃষ্ণনগর
            লোকাল ট্রেন ধরে কৃষ্ণনগর ষ্টেশন নামতে হবে তারপর অটো করে মায়াপুরঘাটে
            আসতে হবে তারপর গঙ্গা পার হয়ে টোটো করে মায়াপুর মেনগেটে আসটে পারবেন।
          </p>
        </div>
      </div>


        {/* 3rd */}
      <div className="xl:flex lg:flex md:flex  my-4 bg-white gap-5 shadow-xl  rounded-lg ">
        <img src="https://mayapursambad.com/image/Connection/NabadwipStation1.jpg" alt="" className=" rounded-lg " />
        <div className="py-3 px-2">
          <img src="https://mayapursambad.com/image/Connection/NabadwipStation.jpg" alt="" />
          <p className="text-black text-xl"> 
            কিভাবে আসবেন: শিয়ালদা থেকে ট্রেনে করে মায়াপুর আসতে হলে কৃষ্ণনগর
            লোকাল ট্রেন ধরে কৃষ্ণনগর ষ্টেশন নামতে হবে তারপর অটো করে মায়াপুরঘাটে
            আসতে হবে তারপর গঙ্গা পার হয়ে টোটো করে মায়াপুর মেনগেটে আসটে পারবেন।
          </p>
        </div>
      </div>

        {/* 4th */}
      <div className="xl:flex lg:flex md:flex  my-4 bg-white gap-5 shadow-xl  rounded-lg mb-6 ">
        <img src="https://mayapursambad.com/image/Connection/EsplanedBustand.jpg" alt="" className=" rounded-lg " />
        <div className="py-3 px-2">
          <img src="https://mayapursambad.com/image/Connection/darmotala.png" alt="" />
          <p className="text-black text-xl"> 
           কিভাবে আসবেন: আপনারা যে কোন বাসে মায়াপুর আসতে হলে বহরমপুর, মালদা বা
            উত্তরবঙ্গের বসে করে ধুবুলিয়াতে (চৌগাছা) নামতে হবে। তারপর বাসে করে বা
            টোটো করে মায়াপুর ইসকন মন্দিরের মেনগেট বা নামহট্ট গেটে নামতে পারেন
            অথবা কৃষ্ণনগর থেকে অটো করে মায়াপুরঘাটে আসবেন নৌকা করে গঙ্গা পার হয়ে
            টোটো করে মায়াপুর মেনগেটে আসটে পারবেন।
          </p>
        </div>
      </div>
    </div>
  );
};

export default Connection;
