import React, { useEffect } from "react";

import ShowBanner from "./ShowBanner";
import io from "socket.io-client";

const socket = io.connect("https://mayapurapp.ap-1.evennode.com");
const Banner = () => {
  const [baneer, setBaneer] = React.useState([]);
  useEffect(() => {
    socket.emit("retrieveAllBanners");
    socket.on("allBannersRetrieved", (data) => {
      setBaneer(data);
    });
  }, []);

  const options = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const kolkataTime = formatter.format(new Date());

  //   const kolkataTimeNumber = Number(kolkataTime.replace(':', ''));

  function convertTimeToMinutes(time) {
    let [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  let totalMinutes = convertTimeToMinutes(kolkataTime);

  for (let i = 0; i < baneer.length; i++) {
    const arotiElement = baneer[i];
    const { startTime, stopTime, skipBanner, gif } = arotiElement;
    const startTimeNumber = +startTime;
    const stopTimeNumber = +stopTime;

    if (
      startTimeNumber <= totalMinutes &&
      totalMinutes <= stopTimeNumber &&
      skipBanner === false
    ) {
      // console.log(arotiElement)

      return (
        <div>
          <ShowBanner gif={gif} />
        </div>
      );
    }
  }
  return (
    <div>
      <ShowBanner />
    </div>
  );
};

export default Banner;
