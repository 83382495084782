import React, { useEffect } from "react"
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useLocation } from "react-router-dom";

const Prasadam = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
      }, [pathname]);
  
  
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const temple1 = [
    {
      url: "https://mayapursambad.com/image/Prasadam/sulavprasadam/SulavaPrasadam1.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Prasadam/sulavprasadam/SulavaPrasadam2.jpg",
    },
    {
        url: "https://mayapursambad.com/image/Prasadam/sulavprasadam/SulavaPrasadam3.jpg",
      },
     {
      url: "https://mayapursambad.com/image/Prasadam/sulavprasadam/SulavaPrasadam4.jpg",
    },
  ];

  const temple2 = [
    {
      url: "https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasadam1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasadam2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasadam3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasadam4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasadam5.jpg",
    },
    
  ];

    const temple3 = [
  {
      url: "https://mayapursambad.com/image/Prasadam/RadhRasamrita/RadhaRasamrita1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/RadhRasamrita/RadhaRasamrita2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/RadhRasamrita/RadhaRasamrita2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/RadhRasamrita/RadhaRasamrita4.jpg",
    },
    
  ];

    const temple4 = [
  {
      url: "https://mayapursambad.com/image/Prasadam/annadan/anna1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/annadan/anna2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/annadan/anna3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/annadan/anna4.jpg",
    },
    
  ];

    const temple5 = [
  {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam6.jpg",
    },
    
  ];

    const temple6 = [
  {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasadam10.jpg",
    },
    
  ];

    const temple7 = [
  {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Prasadam/Govindas/govindas10.jpg",
    },
  ];


  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Prasadam/sulavprasadam/sulab.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            প্রসাদ কুপন সংগ্রহের সময়: সকাল ৬:০০ টা - দুপুর ৩:০০ টা{' '}
            প্রসাদ কুপন সংগ্রহের স্থান: ইসকন মেন গেট, সুলভের গেট{' '}
            প্রসাদের সময়:সকাল - ৮:৩০টা থেকে ১০ টা পর্যন্ত দুপুর - ১২ টা থেকে
            ৩:৩০ পর্যন্ত{' '}
            স্থান: শ্রীল প্রভুপাদ পূষ্পসমাধি মন্দিরের পাশে{' '}
            দূরত্ব: মন্দির থেকে ৪০০ মিটার, মেনগেট থেকে ২০০ মিটার
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Prasadam/gadaprasadam/gadaprasad.png" alt="" />
          <h1 className="text-xl font-medium text-black">
             কুপন সংগ্রহের সময়সূচী:{' '}
            সকাল ৬:৩০ মি - দুপুর ১২:০০ টা বিকাল ৪:৩০ মি - সন্ধ্যা ৭:০০ টা{' '}
            কুপন সংগ্রহের স্থান:{' '}
            ইসকন মেন গেট, গদা রিসেপশন{' '}
            প্রসাদের সময়:{' '}
            ৮০ টাকা (দুপুর ১:০০ টা - ১:৩০ মি), ৫০ টাকা (রাত ৮:৩০ মি - ৯:০০ টা)
            {' '}
            স্থান: চক্র ভবনের পিছনে{' '}
            দূরত্ব: মন্দির থেকে ২০০ মিটার
          </h1>
        </div>
      </div>

      
          
      {/*444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Prasadam/RadhRasamrita/radharasamrita.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            খোলা/বন্ধের সময়সূচী:সকাল ৭:০০ টা - দুপুর ১:০০ টা, দুপুর ২:০০ টা -
            রাত ৮:০০ টা{' '}
            স্ন্যাক্স মেনু:কাপ কেক, পাটিস, ভেজিটেবল বান, মোমো, মিনি পিজা,
            ক্যালজন, প্রভুপাদ কেক, কাজু কেক, ক্রীম কেক, প্লেন কেক, সমোচা, পনীর
            পকোড়া, ধোক্লা, দহি বড়া, ইডলি, ভেজ রোল, উত্তাপাম, প্লেন ধোসা, মসালা
            ধোসা, আলু পরোটা, পরোটা, চাউমিন, পাস্টা দুপুরের থালি:দুপুর ১:০০ টা
            (১২০ টাকা){' '}
            সময়:সকাল ৮:০০ টা থেকে রাত ৮:০০টা পর্যন্ত{' '}
            সুইট মেনু:কালোজাম, সন্দেশ, রসগোল্লা, গুড় রসগোল্লা, চন্দ্রভোগ,
            কাঁচাগোল্লা, ক্ষীরকদম, পেড়া, ল্যাংচা, মোহনভোগ, চমচম, ছানা কেক, ভাপা
            সন্দেশ, দই, লস্যি, কদম মিষ্টি, রসমালাই, ক্ষীর। স্থান:গদা ভবন (নিচ
            তলা){' '}
            দূরত্ব:মন্দির থেকে ২০০ মিটার
          </h1>
        </div>
      </div>

      {/*5555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Prasadam/annadan/annadan.png" alt="" />
          <h1 className="text-xl font-medium text-black">
         কুপন সংগ্রহের স্থান ও সময়:এখানে প্রসাদ পেতে হলে ভোরের মঙ্গল আরতির পর
            মন্দিরে বিনামূল্যে প্রসাদের কুপন পাওয়া যায়{'   '}
            প্রসাদের সময়:দুপুর ১:০০ টায়{'   '}
            স্থান:গোবিন্দ রেস্টুরেন্টের পাশে{'   '}
            দূরত্ব:মন্দির থেকে ১৫০ মিটার
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Prasadam/gitaprasadam/gitaprasadam.png" alt="" />
          <h1 className="text-xl font-medium text-black">
             গীতা ভবনের প্রসাদ কুপন সংগ্রহের সময়:{'   '}সকাল ৭:০০ টা - দুপুর ১২:০০
            টা{'   '}বিকাল ৫:০০ টা - সন্ধ্যা ৭:৩০ মি{'   '}কুপন সংগ্রহের
            স্থান:ইসকন মেন গেট, গীতা রিসেপশন{'   '}প্রসাদের সময়সূচী: দুপুর - ৬০
            টাকা (দুপুর ১:০০ টা - ১:৩০ মি),{'   '}রাত - ৪০ টাকা (রাত ৮:০০ টা -
            ৮:৩০ মি){'   '}স্থান: (গীতা ভবন) বংশী ভবনের নিকটে{'   '}দূরত্ব: মন্দির
            থেকে ২০০ মিটার
          </h1>
        </div>
      </div>

      {/*777777777777777777777777777*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Prasadam/mahaprasadamstore/mahaprasad.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            খোলা/বন্ধের সময়: সকাল ৬:০০ টা - রাত ৯:০০ টা{'    '}বিশেষ থালি: দুপুর
            ১১:৩০ টা (৯০ টাকা){'    '}স্ন্যাক্স মেনু: সিঙ্গারা, বার্গার, পিজা,
            উত্থাপাম, ডোনাট, আলু পরোটা, পনির পরোটা, ধোকলা, ধোসা, প্যাটিস,
            চাউমিন, পাস্তা, পাউভাজি, ক্যালজল, কলাভাজা, চিড়াভাজা, চানাচুর,
            ভুজিয়া, কুকিজ, গাঁঠিয়া সুইটস মেনু: গুলাব জাম, রসগোল্লা, ক্ষীর, কেক,
            গজা, মালপোয়া, লাড্ডূ, হারবাল চা, পেস্ট্রি{'    '}স্থান: মেনগেট, শ্রী
            শ্রীরাধা মাধব মন্দির, শ্রী শ্রীপঞ্চতত্ত্ব মন্দিরের মোবাইল কাউন্টারের
            পাশে, চক্র ভবনের পিছনে, বংশী ভবনের সামনে, বৈদিক তারা মণ্ডলের পাশে।
            {'    '} দূরত্ব:মন্দির থেকে ২০০ মিটার
          </h1>
        </div>
      </div>

      {/*8888888888888888888888888888*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple7.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Prasadam/Govindas/govindas.png" alt="" />
          <h1 className="text-xl font-medium text-black">
               খোলা/বন্ধের সময়সূচী:সকাল ৭:০০ টা - রাত ৯:০০ টা।{'   '}
            প্রাতঃরাশ:পরোটা, সাউথ ইন্ডিয়ান প্রসাদ, কন্টিনেন্টাল প্রসাদ, টোষ্ট,
            স্যান্ডুইস, বার্গার, স্যালাদ, রাইতা, দই{'   '}মধ্যান্ন ও রাত্রকালীন
            ভোজ:বিশেষ থালি, সুপ, বিবিধ রাইস, সবজি, ডাল, চাইনিজ ডিস, ইটালিয়ান
            ডিস, তন্দুরি রুটি, একাদশী প্রসাদ, জুস, মিল্ক সেক, লস্যি, গরম পানীয়।
            {'   '}অর্ডারের সময়:সকাল ৭টা - ১০:৪৫ মি{'   '}দুপুর ১২ টা - ৩:৪৫ মি
            {'   '}বিকাল ৫ টা -রাত ৮:৪৫ মি{'   '}অন্যান্য সুবিধা: সপ্তাহে শুক্র,
            শনি ও রবিবার পিঁজা, জন্মদিন ও বিভিন্ন পার্টি, এডভান্স ও গ্রুপ বুকিং।
            {'   '}যোগাযোগ:{' '}
            
              +৯১ ৭০৪৪২৩৪৯৯৪
            
            {' '}দূরত্ব: মন্দির থেকে ১৫০ মিটার
          </h1>
        </div>
      </div>

     
    </div>
  );
};

export default Prasadam;
