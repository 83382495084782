import React from 'react';
import Navebar from '../Page/Shared/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Page/Shared/Footer';

const Layout = () => {
    return (
        <div>
           <div className="sticky top-0 z-50">
                <Navebar />
            </div>
            <div className="min-h-screen">
                <Outlet/>
            </div>
            <div className="-mb-12">
                <Footer/>
          </div>
        </div>
    );
};

export default Layout;