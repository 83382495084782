import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useLocation } from "react-router-dom";
const Residence = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
 
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const temple1 = [
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso10.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso11.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso12.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso13.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso14.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso15.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Ishodyan/iso16.jpg",
    },
  ];

  const temple2 = [
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/conchbuilding/ConchBhaban6.jpg",
    },
  ];

  const temple3 = [
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban7.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban8.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Residence/VamsiBhavan/VamsiBhaban9.jpg",
    },
  ];

  const temple4 = [
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gadabhaban/gada8.jpg",
    },
  ];

  const temple5 = [
    {
      url: "https://www.iskconbangla.com/img/atithibhaban/AtithiBhaban1.jpg",
    },
    {
      url: "https://www.iskconbangla.com/img/atithibhaban/AtithiBhaban2.jpg",
    },
    {
      url: "https://www.iskconbangla.com/img/atithibhaban/AtithiBhaban3.jpg",
    },
   
  ];

  const temple6 = [
    {
      url: "https://mayapursambad.com/image/Residence/namhattabhaban/namhattabhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/namhattabhaban/namhattabhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/namhattabhaban/namhattabhaban3.jpg",
    },
  ];

  const temple7 = [
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban7.jpg",
    },
  ];

  const temple8 = [
    {
      url:"https://mayapursambad.com/image/Residence/chaitanyabhaban/ChaitanyaBhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/chaitanyabhaban/ChaitanyaBhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/chaitanyabhaban/ChaitanyaBhaban3.jpg",
    },
  ];

  const temple9 = [
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/GourNitaiDharmashala6.jpg",
    },
  ];

  const temple10 = [
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/GourangaKutir/GourangaKuthir7.jpg",
    },
  ];

  const temple11 = [
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/NityanadaKuthir/NityanandaKuthir8.jpg",
    },
  ];

  const temple12 = [
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/srutibhavan/SrutiBhaban8.jpg",
    },
  ];

  const temple13 = [
    {
      url: "https://mayapursambad.com/image/Residence/Chakrabhaban/ChakraBhaban1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Chakrabhaban/ChakraBhaban2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Residence/Chakrabhaban/ChakraBhaban2_Old.jpg",
    },
  ];
  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Residence/Ishodyan/isodhyanbhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (১২১০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (১৫০) টি {' '}
            যোগাযোগ: +৯১ ৯৪৭৪৭৫১২০২, ০৩৪৭২ ২৪৫৬২০{" "} (সকাল ১০ টা - বিকাল ৫ টা){' '}
            স্থান: বংশী ভবনের পাশে{' '}
            দূরত্ব: ৩০০ মিটার মূল মন্দির থেকে{' '}
            ওয়েব সাইট: <a href="https://www.visitmayapur.com/ishodyan-bhavan/"  target="_blank">www.visitmayapur.com</a> {' '}
            ইমেইল: {' '}mghb108@gmail.com 
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Residence/conchbuilding/conchbhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (১৮৫০) রুপি {' '}
            যোগাযোগ: +৯১ ৯৪৭৪৭-৫১২০২, ০৩৪৭২ ২৪৫৬২০{' '}(সকাল ১০ টা - বিকাল ৫ টা){' '}
            স্থান: মায়াপুর চন্দ্রোদয় মন্দিরের পাশে।{' '}
            ওয়েব সাইট: <a href="https://www.visitmayapur.com/conch-bhavan/"  target="_blank">www.visitmayapur.com</a>{' '}
            ইমেইল: {' '}mghb108@gmail.com
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Residence/gadabhaban/gadabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (১২১০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (৮০) টি {' '}
            যোগাযোগ: +৯১ ৮৯৪৪০-৭৪৪৬৪ {' '}
            স্থান: ঈশ্বোদ্যানের আগে {' '}
            দূরত্ব: ২৫০ মিটার মূল মন্দির থেকে {' '}
            ইমেইল: mayapurlifepatron@gmail.com 
          </h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Residence/gadabhaban/gadabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৪০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (২০০) টি {' '}
            যোগাযোগ: +৯১ ৯৪৭৪৭৫১২০২, ০৩৪৭২ ২৪৫৬২০ (সকাল ১০:০০ টা - বিকাল ৫:০০ টা){' '}
            স্থান: চক্র ভবনের পিছনে{' '}
            দূরত্ব: ২০০ মিটার মূল মন্দির থেকে{' '}
            ওয়েব সাইট: <a href=" https://www.visitmayapur.com/gada-bhavan/" target="_blank">www.visitmayapur.com</a> {' '}
            গ্রুপ/ দীর্ঘ দিনের বুকিং: gprb108@gmail.com{' '}
            ইমেইল: mghb108@gmail.com
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/atithibhaban/atithibhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৮০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (৭) টি {' '}
            যোগাযোগ: +৯১ ৭৫০১-৮৩৪৩৮৩ {' '}
            স্থান: গদা ভবনের পাশে{' '}
            দূরত্ব: ২০০ মিটার মূল মন্দির থেকে{' '}
            ওয়েব সাইট: ২০০ মিটার মূল মন্দির থেকে{' '}
            ইমেইল:  gh.iskcon@gmail.com
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/namhattabhaban/namhattabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৩০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (২০০) টি {' '}
            দূরত্ব: ২৫০ মিটার মূল মন্দির থেকে m {' '}
            যোগাযোগ: +৯১ ৭৫৪৮৯-৬৯২৬৯ (সকাল ১০:০০ টা - রাত্রি ৮:০০ টা){' '}
            ইমেইল: iskconnhreception@gmail.com
          </h1>
        </div>
      </div>

      {/*777777777777777777777777777777777777777777777*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple7.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/GitaBhavan/gitabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৫৫০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (২০০) টি {' '}
            যোগাযোগ:  +৯১ ৮৩৪৮৩৭৫৯৯২  {' '}
            স্থান: বংশী ভবনের নিকটে {' '}
            দূরত্ব: ৩০০ মিটার মূল মন্দির থেকে {' '}
            ইমেইল:  gitaacademy@gmail.com
          </h1>
        </div>
      </div>

      {/*888888888888888888888888888888888888888888*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple8.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/chaitanyabhaban/chaitanyabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৪৫০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (২৬) টি {' '}
            যোগাযোগ: +৯১ ৯৭৩২১-০৮০০০{' '}
            স্থান: গদা ভবনের পিছনে{' '}
            দূরত্ব: ২৫০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*99999999999999999999999999999999999999999999*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple9.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/gaurnitaidharmshalla/gaurnitaidharmashala.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (১০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (২৭) টি {' '}
            যোগাযোগ:  +৯১ ৭৭৯৭৮-৫৮৮৫৫ {' '}
            স্থান: নামহট্টের পাশে{' '}
            দূরত্ব: ২২০ মিটার মূল মন্দির থেকে 
          </h1>
        </div>
      </div>

      {/*101010101010101010101010101010*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple10.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/NityanadaKuthir/nityanandakuthir.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (১০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (৮০) টি {' '}
            যোগাযোগ: +৯১ ৭৭৯৭৮-৫৮৮৫৫ {' '}
            স্থান: গোবিন্দাস রেস্টুরেন্টের পাশে {' '}
            দূরত্ব: ১৫০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple11.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/NityanadaKuthir/nityanandakuthir.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (২০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (৯৭) টি {' '}
            যোগাযোগ: +৯১ ৭৭৯৭৮-৫৮৮৫৫ {' '}
            স্থান: গোশালা যাবার রাস্তায় {' '}
            দূরত্ব: ২৫০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*122222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple12.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Residence/srutibhavan/srutibhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
             রুম বুকিং:{' '}
            সর্বনিম্ন মূল্য: (৩০০) রুপি {' '}
            মোট কক্ষ সংখ্যা: (৩১) টি {' '}
            যোগাযোগ: +৯১ (৭০৪৭৬-১০৩০০),(৮৯০৬৯-৩২১১৪) {' '}
            স্থান: গোশালা যাবার রাস্তায় {' '}
            দূরত্ব: ৫০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*1333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple13.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src= "https://mayapursambad.com/image/Residence/Chakrabhaban/chakrabhaban.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           রুম বুকিং: {' '}
            সর্বনিম্ন মূল্য: (৪০০) রুপি {' '}
            মোট কক্ষ সংখ্যা : (৭০) টি {' '}
            যোগাযোগ: +৯১ ৮৯৪৪০-৭৪৪৬৪ {' '}
            স্থান: পদ্ম ভবনের পাশে {' '}
            দূরত্ব: মূল মন্দির থেকে ৫০ মিটার {' '}
            ইমেইল: mayapurlifepatron@gmail.com
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Residence;
