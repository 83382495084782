import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import NewsCard from "./NewsCard";
import MoreNews from "./MoreNews";
import { useLocation } from "react-router-dom";

const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const News = () => {
  const [news, setNews] = useState([]);
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    socket.emit("retrieveHeadlineNews"); //on connection, request 16 news headlines
    socket.on("16NewsHeadlinesRetrieved", (dataObject) => {
      setNews(dataObject);
    });
    return () => {
      socket.off("16NewsHeadlinesRetrieved");
    };
  }, []);

  return (
    <div>

    
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-center  mx-auto">
      {news?.map((item) => (
        <NewsCard key={item._id} items={item} />
      ))}
      </div>

      <MoreNews/>
      </div>
  );
};

export default News;
