import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import logo from '../../../assets/image/icons/logo.jpg'

const Navebar = () => {
  const [button, setButton] = useState(false);
  const [buttontr, setButtontr] = useState(false);
  const [buttont, setButtont] = useState(false);

  const handelTF = () => {
    setButton(!button);
  };
  const handelTFT = () => {
    setButtont(!buttont);
  };
  const handelTFTr = () => {
    setButtontr(!buttontr);
  };

  return (
    // <div className={`navbar bg-slate-300 px-10 ${navbarClasses}`}>
    <div className="navbar bg-slate-50 p-4 rounded-xl  shadow-lg shadow-gray-300  ">
      <div className="navbar-start">
        <Link to="/" className="btn btn-ghost normal-case text-xl">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="navbar-end hidden lg:flex text-black font-semibold">
        <ul className="menu menu-horizontal p-0">
          <li>
            <NavLink to="/news">সংবাদ ক্ষেত্র</NavLink>
          </li>

          <li>
            <NavLink to="/tv">টিভি</NavLink>
          </li>
          <li>
            <NavLink to="/recordingVideos">রেকর্ড ভিডিও</NavLink>
          </li>

          <li className="dropdown dropdown-hover dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="flex items-center gap-1"
              onClick={handelTFT}
            >
              মায়াপুর ঘুরে আসুন <IoIosArrowDown />
            </div>
            {buttont && (
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 w-52 bg-slate-50 rounded-xl  shadow-lg shadow-gray-300  "
              >
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/MayapurTmple">মায়াপুর মন্দির</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/Residence">বাসস্থান</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/Prasadam">প্রসাদম</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/Activity">কার্যকলাপ</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/Opportunities">সুযোগ সুবিধা</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFT}>
                  <NavLink to="/Connection">মায়াপুর ভ্রমণ</NavLink>
                </li>
                <li>
                  <NavLink to="/NewTemple" onClick={handelTFT}>
                    নতুন মন্দির
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="dropdown dropdown-hover dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="flex items-center gap-1"
              onClick={handelTFTr}
            >
              আধ্যাত্মিক জীবন <IoIosArrowDown />
            </div>
            {buttontr && (
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 w-52 bg-slate-50 rounded-xl  shadow-lg shadow-gray-300  "
              >
                <li className="border-b-2 border-gray-300" onClick={handelTFTr}>
                  <NavLink to="/Join">সংযোগ</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFTr}>
                  <NavLink to="/AllTemples">সব মন্দির</NavLink>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFTr}>
                  <a href="https://mayapur.store/" target="_blank">
                    বইয়ের দোকান
                  </a>
                </li>
                <li className="border-b-2 border-gray-300" onClick={handelTFTr}>
                  <NavLink to="/Prabhupada">প্রভুপাদ</NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>

      {/* responive manu  */}

      <div className={`navbar-end gap-4 xl:hidden lg:hidden`}>
        <div className="dropdown dropdown-end">
          <label
            tabIndex={0}
            className="btn btn-ghost lg:hidden"
            onClick={handelTF}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </label>

          {button && (
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 bg-slate-50 rounded-xl shadow-md shadow-gray-300 w-52 text-black"
            >
              <li className="border-b-2 border-gray-300" onClick={handelTF}>
                <NavLink to="/news">সংবাদ ক্ষেত্র</NavLink>
              </li>

              <li className="border-b-2 border-gray-300" onClick={handelTF}>
                <NavLink to="/tv">টিভি</NavLink>
              </li>
              <li className="border-b-2 border-gray-300" onClick={handelTF}>
                <NavLink to="/recordingVideos">রেকর্ড ভিডিও</NavLink>
              </li>

              <li className="border-b-2 border-gray-300">
                <details>
                  <summary>মায়াপুর ঘুরে আসুন</summary>
                  <ul className="p-2 bg-slate-100 text-neutral-950   shadow-lg shadow-gray-300 ">
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/MayapurTmple">মায়াপুর মন্দির</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Residence">বাসস্থান</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Prasadam">প্রসাদম</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Activity">কার্যকলাপ</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Opportunities">সুযোগ সুবিধা</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Connection">মায়াপুর ভ্রমণ</NavLink>
                    </li>
                    <li onClick={handelTF}>
                      <NavLink to="/NewTemple">নতুন মন্দির</NavLink>
                    </li>
                  </ul>
                </details>
              </li>

              <li className="border-b-2 border-gray-300">
                <details>
                  <summary>আধ্যাত্মিক জীবন</summary>
                  <ul className="p-2 bg-slate-100 text-neutral-950   shadow-lg shadow-gray-300 ">
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Join">সংযোগ</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/AllTemples">সব মন্দির</NavLink>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <a href="https://mayapur.store/" target="_blank">
                        বইয়ের দোকান
                      </a>
                    </li>
                    <li
                      className="border-b-2 border-gray-300"
                      onClick={handelTF}
                    >
                      <NavLink to="/Prabhupada">প্রভুপাদ</NavLink>
                    </li>
                  </ul>
                </details>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>

    // </div>
  );
};

export default Navebar;
