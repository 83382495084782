import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import NewsCard from "./NewsCard";


const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const MoreNews = () => {
  const [moreNews, setMoreNews] = React.useState([]);
  const [more, setMore] = useState(false);
  const [startIndex, setStartIndex] = useState(16);
  const [moreButtonYear, setMoreButtonYear] = useState(2023);

  const moreButton = () => {
    socket.emit("get16MoreNewsHeadlines", startIndex, moreButtonYear);
    setMore(true);
  };
  useEffect(() => {
    socket.on("16MoreNewsHeadlinesRetrieved", (dataObject) => {
      // console.log("in loadmore get16MoreNewsHeadlines - startIndex = " + startIndex)
      var more16Headlines = dataObject;
      var getLowestDate = 999999999999999;
      // console.log("more16Headlines.length = " + more16Headlines.length);
      more16Headlines.sort(
        (a, b) => parseFloat(a.block_number) - parseFloat(b.block_number)
      );

      if (more16Headlines?.length > 0) {
        for (var i = more16Headlines?.length - 1; i > 0; i--) {
          if (more16Headlines[i]["block_number"] < 17) {
            more16Headlines?.splice(i, 1);
          } else if (more16Headlines[i]["_id"] < getLowestDate) {
            //get the lowest date to set the year for the more button
            getLowestDate = more16Headlines[i]["_id"];
            // console.log('SSSSSgetLowestDate = ', getLowestDate);
            //console.log("getLowestDate = " + getLowestDate);
            //console.log("more16Headlines.length = " + more16Headlines.length);
            var date = new Date(parseInt(getLowestDate));
            setMoreButtonYear(date?.getFullYear());
          }
        }
      }
      var remainder = 16 - more16Headlines?.length;

      setStartIndex(startIndex + 16);

      // setMoreNews(more16Headlines);
      setMoreNews((prevNews) => [...moreNews , ...more16Headlines]);

      if (remainder > 0) {
        socket.emit("get16MoreNewsHeadlines", startIndex, moreButtonYear);
      }
    });
  }, [more, startIndex, moreButtonYear]);

  return (
    <div className="mb-8">
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-center  mx-auto">
        {moreNews?.map((item) => (
          <NewsCard key={item._id} items={item} />
        ))}
      </div>

      <div className="text-center my-5">
        <button className="btn bg-blue-500  hover:bg-blue-800  text-white px-14" onClick={moreButton}>
          আরো খবর..
        </button>
      </div>
    </div>
  );
};

export default MoreNews;
