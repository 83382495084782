import React, { useEffect } from 'react';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { useLocation } from 'react-router-dom';
const Activity = () => {
  const { pathname } = useLocation(); 
      useEffect(() => {
    window.scrollTo(0, 0);
      }, [pathname]);
  
   
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };
  const temple1 = [
    {
      url: "https://mayapursambad.com/image/Activity/TovpTour/TOVPTour1.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/TovpTour/TOVPTour2.jpg",
    }, {
      url: "https://mayapursambad.com/image/Activity/TovpTour/TOVPTour3.jpg",
    },{
      url: "https://mayapursambad.com/image/Activity/TovpTour/TOVPTour4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/TovpTour/TOVPTour5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/TovpTour/TOVPTour6.jpg",
    },
    ];
  const temple2 = [
     {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater1.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/Theater/Theater2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Theater/Theater9.jpg",
    },
    ];
  const temple3 = [
   {
      url: "https://mayapursambad.com/image/Activity/boat/boat1.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/boat/boat2.jpg",
    },
  ];
  const temple4 = [
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition7.jpg",
    },{
      url: "https://mayapursambad.com/image/Activity/chaitanyaexhibition/ChaitanyaExhibition8.jpg",
    },
    
  ];

    const temple5 = [
  
    {
      url: "https://mayapursambad.com/image/Activity/bhoga/bhoga1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/bhoga/bhoga2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/bhoga/bhoga3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/bhoga/bhoga4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/bhoga/bhoga5.jpg",
    },
    
  ];

    const temple6 = [
  {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition10.jpg",
    },
    
  ];

    const temple7 = [
  {
      url: "https://mayapursambad.com/image/Activity/outdoorclass/outdoorclass1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/outdoorclass/outdoorclass2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/outdoorclass/outdoorclass3.jpg",
    },
   
  ];

    const temple8 = [
   {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/Goshala/Goshala9.jpg",
    },
    
  ];

    const temple9 = [
  {
      url: "https://mayapursambad.com/image/Activity/elephantprocession/ElephantProcession1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/elephantprocession/ElephantProcession2.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/elephantprocession/ElephantProcession3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/elephantprocession/ElephantProcession4.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/elephantprocession/ElephantProcession5.jpg",
    }
  ];

  const temple10 = [
    {
      url: "https://mayapursambad.com/image/Activity/BullockCart/BullockCart1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/BullockCart/BullockCart2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/BullockCart/BullockCart3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/BullockCart/BullockCart4.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Activity/BullockCart/BullockCart5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Activity/BullockCart/BullockCart6.jpg",
    }
  ];

  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Activity/TovpTour/tovptour.png" alt="" />
          <h1 className="text-xl font-medium text-black">
          সময়সূচি: ১১:০০ টা / ৪:০০টা, (নতুন মন্দির) ৩০ মিনিটের জন্য দর্শন
         স্থান: চক্র ভবনের পাশে।
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Activity/Theater/Theater.png" alt="" />
          <h1 className="text-xl font-medium text-black">
              খোলা/বন্ধের সময়: সকাল ১০:০০টা - রাত ৮:০০টা
            চলচ্চিত্রের সময়সূচি:সকাল ১০:১০মি, সকাল ১০:৩০মি, দুপুর ১২:৫০মি, দুপুর
            ০২:১০মি, দুপুর ৩:৩০মি, বিকাল ৪:৫০মি, সন্ধ্যা ৬:১০মি, সন্ধ্যা ৭:৩০মি।
            বিস্তারিত:মায়াপুরে প্রথমবার ! পবিত্র ধাম এক অনন্য আধ্যাত্মিক
            অভিজ্ঞতা লাভ করুণ। এখানে ৩৬০ ডিগ্রি ভিডিও দেখার অভিজ্ঞতা লাভ করবেন।
            প্রতি শো ২০ মিনিট করে দেখতে পাবেন।
            মূল্যে: ৪০.০০ টাকা
            স্থান: গোবিন্দাস রেস্টুরেন্টের পিছনে
            দূরত্ব:২০০ মিটার মূল মন্দির থেকে
            গ্রুপ বকিং:
              {' '}
              +৯১ ৭০২৯৭৪৬৪৫৬
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Activity/boat/boat.png" alt="" />
          <h1 className="text-xl font-medium text-black">
              সময়: দুপুর ৩:০০টা - বিকাল ৪:০০টা {' '}
            ভাড়া: ১০০.০০ টাকা{' '}
            মোবাইল:
              {' '}
              +৯১ ৯৭৩৫৯২৬০৪৪ {' '}
            বুকিং স্থান:গদা ভবনের সামনে{' '}
            দূরত্ব: ২০০ মিটার মূল মন্দির থেকে</h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Activity/chaitanyaexhibition/chaitanyaexhibit.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           প্রদর্শনী সময়সূচী: {' '}
            ভোর ৫:০০টা - সন্ধ্যা ৭:০০টা{' '}
            সকাল ৯:০০টা - দুপুর ১:০০টা{' '}
            সকাল ৮:০০টা - রাত ৮:০০টা PM{' '}
            স্থান: মায়াপুর চন্দ্রোদয় মন্দির{' '}
            প্রদর্শনীর মূল্য: ০.০০ টাকা
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/bhoga/bhogabhandar.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           পূজা: শ্রী শ্রীনৃসিংহদেব, শ্রী শ্রীরাধা মাধব, শ্রী শ্রীপঞ্চতত্ত্ব -
            ১০১ টাকা ও ৫০১ টাকা{' '}
            বিশেষ পূজা: ১০০১ টাকা, ১০৮ তুলসী পূজা - ৩০১ টাকা, প্রদীপ - ৫ টাকা
            মালা - ২০১ টাকা, তূলসী মালা - ৫০১ টাকা{' '}
            স্থান: শ্রী শ্রীরাধা মাধব মন্দিরের গেটের পাশে{' '}
            সময়: সকাল ৭:০০ টা - দুপুর ১:০০ টা বিকাল ৪:০০টা - রাত ৮:০০ টা
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/samadhiexhibition/samadhiexhibition.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           মিউজিয়ামের খোলা/বন্ধের সময় :{' '}
            সকাল ৯:০০টা - দুপুর ১:০০টা{' '}
            বিকাল ৩:৩০মি. - রাত ৮:৪৫মি.{' '}
            প্রদর্শনীর মূল্য: ১৫.০০ টাকা{' '}
            স্থান: শ্রীল প্রভুপাদ পুস্প সমাধি মন্দির{' '}
            দূরত্ব: ৪০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*777777777777777777777777777777777777777777777*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple7.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/outdoorclass/outdoorclass.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           ঘরে বসে কৃষ্ণভক্তির আধ্যাত্মিক গাইড{' '}
            কার্যক্রম: ভাগবত কথা ও প্রশ্ন উত্তর{' '}
            সময়সূচী: দুপুর ১২:০০টা - বিকাল ৪:০০টা{' '}
            যোগাযোগ:{' '}
              +৯১ ৮৯৪৫৫-১৪৬১২
            {' '}
            স্থান: গোবিন্দাস রেস্টুরেন্টের সামনে{' '}
            দূরত্ব: ১০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*888888888888888888888888888888888888888888*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple8.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/Goshala/goshala.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           গরু: ৪০০+ টি{' '}
            খোলা/বন্ধের সময়: ভোর ৬:০০ টা - সন্ধ্যা ৬:০০ টা{' '}
            দূরত্ব: ৫০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*99999999999999999999999999999999999999999999*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple9.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/elephantprocession/elephant.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           সময়সূচী: প্রতি শনিবার রাস পূর্ণিমা থাকে দোল পূর্ণিমা পর্যন্ত মূল
            মন্দির থেকে রাধা মাধবকে হাতির পিঠে বসিয়ে মায়াপুর সম্পূর্ণ ক্যাম্পাস
            পরিক্রমা করা হয়। (৪:৩০মি) পরিক্রমার দূরত্ব: ১ কি.মি.
          </h1>
        </div>
      </div>

      {/*101010101010101010101010101010*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple10.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Activity/BullockCart/bullockcart.png" alt="" />
          <h1 className="text-xl font-medium text-black">
          গরুর গাড়ীতে মায়াপুর দর্শন: গোশালা থেকে গোবিন্দ রেস্টুরেন্ট পর্যন্ত
            মায়াপুর ক্যাম্পাস দর্শন।{' '}
            মূল্য: ১০.০০ টাকা{' '}
            ভ্রমনের দূরত্ব: ৩০০ মিটার
          </h1>
        </div>
      </div>

    
    </div>
  );
};

export default Activity;