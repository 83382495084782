import React, { useEffect } from "react";
import img from "../../../assets/image/baneer/baneer2.jpg";
import { useLocation } from "react-router-dom";

const Policy = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="px-3">
      
      <div>
        <img src={img} alt="" className="w-fit mx-auto my-5 rounde-md" />
      </div>
      {/* main worke stat */}

      <p
        style={{
          fontSize: 25,
          fontWeight: "bold",
          marginVertical: 10,
          color: "black",
        }}
      >
        General policy
      </p>
      <p style={{ color: "black" }}>
        This general policy applies between you, the User of this Website and
        Mayapur Sambad, the owner and provider of this Website.
      </p>

      <p
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginVertical: 10,
          color: "black",
        }}
      >
        Links to other websites
      </p>

      <p style={{ color: "black" }}>
        1. This Website may, from time to time, provide links to other websites.
        We have no control over such websites and are not responsible for the
        content of these websites.
      </p>
      {/*  2*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          Copyright
        </p>
        <p style={{ color: "black" }}>
          1. Mayapur Sambad wishes to inform all concerned that all live and
          recorded content, comments and ps that appear on this website are
          copyrighted. Therefore they can only be reproduced with the prior
          written permission of the author and/or publisher.
        </p>
        <p style={{ marginTop: 20, color: "black" }}>
          For more information contact info@mayapur.tv{" "}
        </p>
      </div>
      {/*  3*/}
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          External Websites
        </p>
        <p style={{ color: "black" }}>
          1. Mayapur Sambad is not responsible for the content of external
          internet sites that may carry a link on our Mayapur Sambad site as well
          as those sites we link to via iFrame.
        </p>
      </div>
      {/*  4*/}
      <div style={{ marginBottom: 25 }}>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginVertical: 10,
            color: "black",
          }}
        >
          General
        </p>
        <p style={{ color: "black" }}>
          1. This policy was created using a document from Rocket Lawyer
          (https://www.rocketlawyer.co.uk).
        </p>
      </div>
    </div>
  );
};

export default Policy;
