import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const RecordingVideos = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [video, setVideo] = useState([]);

  useEffect(() => {
    socket.emit("retrieveAllVideos");
    socket.on("allVideosRetrieved", (data) => {
      setVideo(data);
    });
  }, []);

  return (
    <div className="mb-5">
      {video.map((item, index) => (
        <div
          key={index}
          className="max-w-full  my-10 bg-slate-50 rounded-xl  shadow-lg shadow-gray-300  p-2"
        >
          <div className="max-w-full">
            <iframe
              className="w-full xl:h-[750px] lg:h-[650px] md:h-[430px] sm:h-[400px] h-[200px]  rounded-xl "
              src={item.url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
          </div>
          <p className="py-2 text-black">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default RecordingVideos;
