import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { useLocation } from "react-router-dom";

const Opportunities = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const temple1 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/one/GourangaTravels2.jpg",
    },
  ];
  const temple2 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/one/CyberShop1.jpg",
    },
  ];
  const temple3 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/Parking/Parking1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Parking/Parking2.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Opportunities/Parking/Parking1_Old.jpg",
    },
  ];

  const temple4 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/7.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Opportunities/Shops/8.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Opportunities/Shops/9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/10.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/11.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/12.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/13.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/14.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/15.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/16.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/17.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/18.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/19.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/20.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/28.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/21.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/22.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/23.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/24.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/25.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/26.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/27.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/30.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/31.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/32.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/33.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/34.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/35.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/36.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/37.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/38.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/39.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/40.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/41.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/42.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/43.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/44.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/45.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/46.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/47.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/48.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/49.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/50.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/51.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Shops/52.jpg",
    },
  ];

  const temple5 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/one/medicalstore1.jpg",
    },
  ];

  const temple6 = [
    {
      url:"https://mayapursambad.com/image/Opportunities/one/CurrencyExchange1.jpg"
    },
  ];

  const temple7 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/banks/atm1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/banks/atm2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/banks/atm3.jpg",
    },
    {
      url:"https://mayapursambad.com/image/Opportunities/banks/atm4.jpg",
    },
  ];

  const temple8 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/one/InternalTransport1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/one/InternalTransport2.jpg",
    },
  ];

  const temple9 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/drinkingwater/DrinkingWater6.jpg",
    },
  ];

  const temple10 = [
    {
      url: "https://mayapursambad.com/image/Opportunities/Toitlet/Toilet1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/Opportunities/Toitlet/Toilet2.jpg",
    },
  ];

  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Opportunities/one/gaurangatravel.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           মায়াপুর থেকে কোলকাতা আসা-যাওয়া: ছোট গাড়ী, বাস
            দর্শন: গঙ্গাসাগর, তারাপীঠ, একচক্রা, হাজারদুয়ারি, শান্তিনিকেতন
            টিকিট: ট্রেন, ফ্লাইট
            নবদ্বীপ ভ্রমণ: নবদ্বীপ ধামের বিভিন্ন স্থান দর্শন
                      যোগাযোগ : +৯১ ৯৫৯৩৪৭৯৯৯০ <span>ওয়েব সাইট: <a href="https:// www.gourangatravels.in" target="_blank" rel="noreferrer">  www.gourangatravels.in</a></span> {''}ইমেইল:  travels@gmail.com   -দূরত্ব: ২০০ মিটার মূল মন্দির থেকে
            স্থান: গদা ভবনের সামনে
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Opportunities/one/cybershop.png" alt="" />
          <h1 className="text-xl font-medium text-black">
             সুবিধা: প্রিন্টিং - সাদা-কালো ও রঙিন, email-চেকিং ও ওয়েভ ব্রাউজিং,
            ফটোকপি, ল্যামিনেশন ও ফ্যাক্স, ফটোকপি, ল্যামিনেশন ও ফ্যাক্স
            যোগাযোগ :  ০৩৪৭২ ২৪৫২৪০, ০৩৪৭২ ২৪৫২৩৯
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Opportunities/Parking/parking.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           স্থান: ইস্কন মন্দিরের প্রবেশ পথ নামহট্টের পাশে ৪ নং গেট দিয়ে ।
            মূল্য: বাইক- ২০ টাকা, টোটো, অটো, মটর ভ্যান - ৫০টাকা, ছোট গাড়ি- ১০০
            টাকা, বাস - ২০০ টাকা।
            ওয়েব সাইট:{' '} <a href="https://www.visitmayapur.com/">www.visitmayapur.com</a> {''}ইমেইল:{' '} mghb108@gmail.com
          </h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img src="https://mayapursambad.com/image/Opportunities/Shops/shopping.png" alt="" />
          <h1 className="text-xl font-medium text-black">
            সংকীর্তন ষ্টোর, সিপিডি ষ্টোর, নামহট্ট ষ্টোর, গদা ষ্টোর, শ্রুতি ভবন
            ষ্টোর, গোশালা ষ্টোর, অন্যান্য ষ্টোর
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/one/medicalshop.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           স্থান: ইসকন মেন গেটের পাশে।
            সময়সূচী: সকাল ৮ টা থেকে রাত ৮ টা পর্যন্ত।
            দূরত্ব: ৪০০ মিটার মূল মন্দির থেকে
          </h1>
        </div>
      </div>

      {/*66666666666666666666666666666666666666*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple6.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/one/moneyexchange.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           সুবিধা: বৈদেশিক মূদ্রা বিনিময়, ফ্লাইট টিকিট{' '}
            দূরত্ব: ২০০ মিটার মূল মন্দির থেকে{' '}
            স্থান: চক্র ভবন, রুম নং - ১২৯, ১৩০{' '}
            সময়: সকাল ১০:০০ টা - দুপুর ২:০০ টা{' '}
            যোগাযোগ:{' '} +৯১ ৮১৮৭০-৫৯৯৬৬
          </h1>
        </div>
      </div>

      {/*777777777777777777777777777777777777777777777*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple7.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/banks/atm.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           এটিএম স্থান: Axis ব্যাংক - স্থান - চক্র ভবন, রুম নং - ১৩৩ (২০০ মিটার
            মূল মন্দির থেকে), HDFC ব্যাঙ্ক - স্থান - চক্র ভবন, রুম নং - ১৩৬ (২০০
            মিটার মূল মন্দির থেকে), SBI ব্যাংক - স্থান - পুলিশ ফাঁড়ির পাশে (৪০০
            মিটার মূল মন্দির থেকে), ICICI ব্যাংক - স্থান - ঈশ্বোদ্যান ভবন (৪০০
            মিটার মূল মন্দির থেকে)
          </h1>
        </div>
      </div>

      {/*888888888888888888888888888888888888888888*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple8.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/one/transport.png" alt="" />
          <h1 className="text-xl font-medium text-black">
          স্থান: মায়াপুর ক্যাম্পাসের যেকোনো জায়গায়
          </h1>
        </div>
      </div>

      {/*99999999999999999999999999999999999999999999*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple9.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/drinkingwater/drinkingwater.png" alt="" />
          <h1 className="text-xl font-medium text-black">
           ফ্রি পানীয় জল সুবিধা: চক্র ভবনের সামনে, চক্র ভবনের পিছনে, মায়াপুর
            চন্দ্রোদয় মন্দিরে, সমাধি মন্দিরের সামনে, মায়াপুর ডিজিটাল তারামণ্ডলের
            পাশে, গোবিন্দ রেস্টুরেন্টের পাশে
          </h1>
        </div>
      </div>

      {/*101010101010101010101010101010*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple10.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                   className= "h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img src="https://mayapursambad.com/image/Opportunities/Toitlet/toilet.png" alt="" />
          <h1 className="text-xl font-medium text-black">
          স্থান: বাস পার্কিং, গোবিন্দাস রেস্টুরেন্টের পাশে, শ্রীল প্রভুপাদ
            পূষ্পসমাধির সামনে, সমাধি মন্দিরের সামনে
          </h1>
        </div>
      </div>

    
    </div>
  );
};

export default Opportunities;
