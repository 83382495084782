import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Home from "../Page/Home/Home";
import News from "../Page/NewsFid/News";
import NewsDetls from "../Page/NewsFid/NewsDetls";
import Tv from "../Page/Tv/Tv";
import RecordingVideos from "../Page/RecordingVideos/RecordingVideos";
import Connection from "../Page/Connection/Connection";
import Activity from "../Page/Activity/Activity";
import Join from "../Page/Join/Join";
import Opportunities from "../Page/Opportunities/Opportunities";
import Policy from "../Page/Policy/Policy";
import PrivetPlolicy from "../Page/Policy/PrivetPlolicy";
import Prabhupada from "../Page/Prabhupada/Prabhupada";
import Prasadam from "../Page/Prasadam/Prasadam";
import Residence from "../Page/Residence/Residence";
import AllTemples from "../Page/Temple/AllTemples";
import NewTemple from "../Page/Temple/NewTemple";
import MayapurTmple from "../Page/Temple/MayapurTmple";

const roueter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/news", element: <News /> },
      {
        path: "/newsDetails/:id",
        element: <NewsDetls />,
      },
      { path: "/tv", element: <Tv /> },
      { path: "/Connection", element: <Connection /> },
      { path: "/Activity", element: <Activity /> },
      { path: "/Join", element: <Join /> },
      { path: "/Opportunities", element: <Opportunities /> },
      { path: "/GeneralPolicy", element: <Policy /> },
      { path: "/PrivetPolicy", element: <PrivetPlolicy /> },
      { path: "/Prabhupada", element: <Prabhupada /> },
      { path: "/Prasadam", element: <Prasadam /> },
      { path: "/Residence", element: <Residence /> },
      { path: "/AllTemples", element: <AllTemples /> },
      { path: "/NewTemple", element: <NewTemple /> },
      { path: "/MayapurTmple", element: <MayapurTmple /> },
      { path: "/RecordingVideos", element: <RecordingVideos /> },
    ],
  },
]);
export default roueter;