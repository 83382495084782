import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useLocation } from "react-router-dom";

const MayapurTmple = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };
  const temple1 = [
    {
      url: "https://mayapursambad.com/image/temple/radhamadhabtemple/radhamadhabtemple1.jpg",
    },
  ];
  const temple2 = [
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple10.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple11.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/samadhitemple/samadhitemple12.jpg",
    },
  ];
  const temple3 = [
    {
      url: "https://mayapursambad.com/image/temple/bhajankuthir/BhajanKuthir1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/bhajankuthir/BhajanKuthir2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/bhajankuthir/BhajanKuthir3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/bhajankuthir/BhajanKuthir4.jpg",
    },
  ];

  const temple4 = [
    {
      url: "https://mayapursambad.com/image/temple/gangatemple/GangaTemple1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/gangatemple/GangaTemple2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/gangatemple/GangaTemple3.jpg",
    },
  ];

  const temple5 = [
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple1.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple2.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple3.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple4.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple5.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple6.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple7.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple8.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple9.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple10.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple11.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple12.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple13.jpg",
    },
    {
      url: "https://mayapursambad.com/image/temple/rajapurjagannath/JagannathTemple14.jpg",
    },
  ];

  return (
    <div>
      {/*111111111111111*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple1.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/temple/radhamadhabtemple/radhamadhava.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            মন্দির খোলা/বন্ধের সময়সূচী:
            <br />
            মন্দির খোলার সময় ভোর ৪টা ৩০ মিনিট দুপুর ১টায় মন্দির বন্ধ হয় মন্দির
            বন্ধ হয় ৮টা ৩০ মিনিটে মন্দির বন্ধ হয় ৮টা ৩০ মিনিটে মন্দিরে আরতির
            সময়সূচী: মঙ্গল আরতি - ভোর ৪টা ৩০ মিনিট দর্শন আরাতি - সকাল ৭টায় ভোগ
            আরতি - দুপুর ১২ টায় ধুপ আরতি বিকাল ৪ টায় সন্ধ্যা আরতি - ৬টা ৩০
            মিনিটে
          </h1>
        </div>
      </div>

      {/*2222222222222222222222*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple2.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/temple/samadhitemple/pushpasamadhi.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            মন্দির খোলা/বন্ধের সময়:
            <br />
            ভোর ৪:০০টা - দুপুর ১:০০টা দুপুর ৩:৩০মি - রাত ৯:০০টা মন্দিরে আরতির
            সময়:
            <br />
            ভোর ৮:১০মি - মঙ্গল আরতি সকাল ৭:০০টা - দর্শন আরাতি সকাল ১২:০০টা -
            দর্শন আরাতি দুপুর ৩:০০টা - ধুপ আরতি সন্ধ্যা ৬:০০টা - সন্ধ্যা আরতি
          </h1>
        </div>
      </div>

      {/*3333333333333333333333333333*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple3.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/temple/bhajankuthir/bhajankutir.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            মন্দিরে আরতির সময়সূচী: <br />
            মঙ্গল আরতি - ভোর ৪টা ১০ মিনিট দর্শন আরাতি - সকাল ৭টা ৩০ মিনিট ধুপ
            আরতি বিকাল ৩টা ৩০ মিনিট সন্ধ্যা আরতি - ৫টা ৫০ মিনিটে
          </h1>
        </div>
      </div>

      {/*44444444444444444444444444444444*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple4.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5  px-2">
          <img
            src="https://mayapursambad.com/image/temple/gangatemple/gangamatatemple.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            স্থান: শ্রীল প্রভুপাদ ঘাট ইসকন, শ্রীধাম মায়াপুর, নদিয়া - ৭৪১৩১৩, প.
            ব. <br />
            মন্দির খোলা/বন্ধের সময়সূচী: <br />
            মন্দির খোলার সময় ভোর ৪ টায় দুপুর ১টায় মন্দির বন্ধ হয় মন্দির খোলার
            সময় বিকাল ৩ টা ৩০ মিনিটে মন্দির বন্ধ হয় রাত্রি ৮ টায় মন্দিরে আরতির
            সময়সূচী: মঙ্গল আরতি - ভোর ৪ টায় দর্শন আরাতি - সকাল ৬ টা ৩০ মিনিটে
            ভোগ আরতি - দুপুর ১১ টা ৩০ মিনিটে ধুপ আরতি - বিকাল ৩ টা ৩০ মিনিটে
            সন্ধ্যা আরতি - ৬ টায় প্রতিদিন বিকাল ৫টায় শ্রী গঙ্গা মাতার পূজা দর্শন
            করতে পারেন যোগাযোগের মাধ্যম - শ্রী গঙ্গা পূজা সেবা অফিস: শ্রাদ্ধ,
            পিতৃ তর্পণ, গঙ্গা মাতা পূজা, জন্ম দিন, বৈষ্ণব সেবা (৫, ১০, ১৫, ২০
            আরও বেশী জনের) ও অন্যান্য পারিবারিক অনুষ্ঠানের সুযোগ আপনি নিটে পারেন
          </h1>
        </div>
      </div>

      {/*555555555555555555555555555555*/}
      <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 bg-white gap-5 shadow-xl  pb-1 rounded-lg my-10 mx-3 ">
        <div className="slide-container">
          <Slide>
            {temple5.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage.url})`,
                  }}
                  className="h-72 xl:h-[400px] md:h-[350px] lg:h-[350px] rounded-lg  flex items-center justify-center bg-cover"
                ></div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="py-5 px-2">
          <img
            src="https://mayapursambad.com/image/temple/rajapurjagannath/rajapurjagannath.png"
            alt=""
          />
          <h1 className="text-xl font-medium text-black">
            মন্দির খোলা/বন্ধের সময়সূচী:
            <br />
            মন্দির খোলার সময় ভোর ৪টা ৩০ মিনিট দুপুর ১টায় মন্দির বন্ধ হয় মন্দির
            খোলার সময় বিকাল ৪টা মন্দির বন্ধ হয় ৮টা ৩০ মিনিটে মন্দিরে আরতির
            সময়সূচী: মঙ্গল আরতি - ভোর ৪টা ৩০ মিনিট দর্শন আরাতি - সকাল ৭টায় ভোগ
            আরতি - দুপুর ১২ টায় ধুপ আরতি বিকাল ৪ টায় সন্ধ্যা আরতি - ৬টা ৩০
            মিনিটে ইস্কন মন্দির থেকে রাজাপুরের দূরত্ব: ৫ কি.মি.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default MayapurTmple;
