import React, { useEffect } from "react";
import News from "../NewsFid/News";
import Banner from "./Banner/Banner";
import { useLocation } from "react-router-dom";

const Home = () => {
    const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <div>
        <Banner />
      </div>
      <News />
      <div></div>
    </div>
  );
};

export default Home;
