import React, { useEffect } from "react";
import banner from "../../../assets/image/baneer/baneer2.jpg";
import { FaFax, FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useLocation } from "react-router-dom";

const NewTemple = () => {
  const { pathname } = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <div>
        <img src={banner} alt="" className="w-fit mx-auto my-1" />
      </div>
      <div>
        <h1 className="text-center  font-semibold text-2xl text-black my-5 underline">
          নতুন মন্দির
        </h1>

        <div className="max-w-3xl  mx-auto max-h-[504px] mb-2 ">
          <iframe
            className="w-full"
            // width="560"
            height="431"
            src="https://www.youtube.com/embed/NHbSm7pU-tY?si=BvPCz2aJ4JjJjWHn"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div>
        <h1 className="text-center  font-semibold text-2xl text-black my-6 underline">
          মন্দিরের বিস্তারিত
        </h1>
        <div className="max-w-3xl  mx-auto">
          <div>
            <a
              href="https://www.tovp.org"
              target="_blank"
              className="flex gap-1 items-center text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
              <span className="font-semibold text-xl">:</span>
              <span className="font-semibold text-xl">
                https://www.tovp.org
              </span>
            </a>
          </div>

          <div className="my-1">
            <a
              href="https://www.youtube.com/c/TOVPinfoTube"
              target="_blank"
              className="flex gap-1 items-center text-black"
            >
              <FaYoutube className="w-7 h-7 text-red-600" />

              <span className="font-semibold text-xl">:</span>
              <span className="font-semibold text-xl">
                https://www.youtube.com/c/TOVPinfoTube
              </span>
            </a>
          </div>

          <div>
            <a className="flex gap-1 items-center text-black">
              <FaPhoneAlt className="w-6 h-6" />
              <span className="font-semibold text-xl">:</span>
              <span className="font-semibold text-xl"> +91 (3472) 245214</span>
            </a>
          </div>

          <div className="my-1">
            <a className="flex gap-1 items-center text-black">
              <FaFax className="w-6 h-6" />
              <span className="font-semibold text-xl">:</span>
              <span className="font-semibold text-xl"> +91 (3472) 245214</span>
            </a>
          </div>

          <div className="my-1">
            <a className="flex gap-1 items-center text-black">
              <MdEmail className="w-7 h-7" />
              <span className="font-semibold text-xl">:</span>
              <span className="font-semibold text-xl">info@tovp.org</span>
            </a>
          </div>
         
              </div>
               <div className="my-7 text-center  ">
            <p className="text-black text-xl"> 
              ISKCON Mayapur
              <br />
              Chakra Building, Room 204
              <br />
              Dist. Nadia, West Bengal India, 741313
            </p>
          </div>
      </div>
    </div>
  );
};

export default NewTemple;
