import React, { useEffect, useState } from "react";
import live from "../../videos/live.mp4";
import notLive from "../../videos/notLive.mp4";
import './ticker.css'
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import io from "socket.io-client";

const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const ShowBanner = ({ gif }) => {
 

    

  const [LiveBanner, setLiveBanner] = useState([]);
  useEffect(() => {
    socket.emit("retrieveAllAppData");
    socket.on("allAppDataRetrieved", (data) => {
      setLiveBanner(data);
    });
  }, []);
  // const api = LiveBanner.slice(3, 4);
  const title = LiveBanner.slice(2, 3);

  useEffect(() => {
   
    const handleFirstUserInteraction = () => {
      window.removeEventListener("click", handleFirstUserInteraction);
      window.removeEventListener("keydown", handleFirstUserInteraction);
    };
    window.addEventListener("click", handleFirstUserInteraction);
    window.addEventListener("keydown", handleFirstUserInteraction);
    return () => {
      window.removeEventListener("click", handleFirstUserInteraction);
      window.removeEventListener("keydown", handleFirstUserInteraction);
    };
  }, []);


  return (
    <div className="my-2 mb-6">

    <div className="flex justify-center items-center mx-auto max-w-[888px] ">
      <div id="marquee-wrapper" >
        <Marquee direction="left" speed={50} delay={0}>
          {title?.map((item, index) => (
            item.title === "True" && (
              <Link  to="/tv" key={index}>
                <h1  className="text-black  text-xl font-medium my-4">
                  {item?.url} ।
                </h1>
              </Link>
            ) 
          ))}
        </Marquee>
      </div>
      </div>




<div className="flex justify-center items-center mx-auto max-w-[1340px]  ">
            <Link to="/tv">
            <img
              className="w-full h-20 xl:h-44 md:h-40 lg:h-44 rounded-sm"
              // src={`https://www.iskconbangla.com/img/whatson/${gif}.png`}
              src={`https://www.iskconbangla.com/img/_topbanner/${gif}.jpg`}
              alt=""
              />
               </Link>
          </div>




      {/* {api.map((item, index) => {
        return item.title === "True" ? (
          <div key={index} className="flex justify-center items-center mx-auto max-w-[888px] xl:max-h-[500px] lg:max-h-[500px] md:max-h-[500px] relative">
            <Link to="/tv">
            
           
            <video
              className="max-w-full xl:max-w-[888px] xl:max-h-[500px] lg:max-h-[500px] md:max-h-[500px]"
              autoPlay
              loop
              muted
            >
              <source src={live} type="video/mp4" />
            </video>
            <img
              className="absolute bottom-0 left-0"
              src={`https://www.iskconbangla.com/img/whatson/${gif}.png`}
              alt=""
              />
               </Link>
          </div>
        ) : (
          <div className="flex justify-center items-center xl:max-h-[500px] lg:max-h-[500px] md:max-h-[500px]">
           <Link to="/tv">
            <video
              className="xl:max-w-full xl:max-h-[500px] lg:max-h-[500px] md:max-h-[500px]"
              autoPlay
              loop
              muted
            >
              <source src={notLive} type="video/mp4" />
              </video>
               </Link>
          </div>
        );
      })} */}
    </div>
  );
};

export default ShowBanner;
