/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, NavLink } from "react-router-dom";
import logo from "../../../assets/image/icons/logo.jpg";

import splace from "../../../assets/image/icons/splace.png";

import playstore from "../../../assets/image/icons/google-play.webp"


import { FaFacebook, FaInstagramSquare, FaYoutube } from "react-icons/fa";

const Footer = () => {
const currentYear = new Date().getFullYear();
  
    return (
        <div>
          
     <footer className="px-4 divide-y bg-gray-100 text-gray-800">
	<div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
		<div className="lg:w-1/3">
			<a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
				{/* <div className="flex items-center justify-center w-12 h-12 rounded-full dark:bg-violet-600">
					
				</div> */}
                <img src={splace} alt="" className="w-12 h-12" />
				<span className="self-center text-2xl font-semibold"><img src={logo} alt="" /></span>
			</a>
		</div>
       <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
              
			<div className="space-y-3">
				<h3 className="tracking-wide font-bold text-lg text-gray-900">সেবা</h3>
				<ul className="space-y-1">
                  <li className="underline">
            <NavLink to="/news">সংবাদ ক্ষেত্র</NavLink>
          </li>
        
          <li className="underline">
            <NavLink to="/tv">টিভি</NavLink>
          </li>
          <li className="underline">
            <NavLink to="/recordingVideos">রেকর্ড ভিডিও</NavLink>
          </li>
				</ul>
			</div>
			<div className="space-y-3">
				<h3 className="tracking-wide font-bold text-lg text-gray-900">প্রতিষ্ঠান</h3>
				<ul className="space-y-1">
					<li className="underline">
						<Link to='/GeneralPolicy'>নীতিমালা</Link>
					</li>
					<li className="underline">
						<Link to='/PrivetPolicy'>গোপনীয়তা নীতি</Link>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<div className="tracking-wide font-bold text-lg text-gray-900">সামাজিক মাধ্যম</div>
				<div className="flex justify-start space-x-3">
					<a rel="noopener noreferrer" href="https://www.facebook.com/Mayapur.tv" title="Facebook" className="flex items-center p-1">
						<FaFacebook  className="text-3xl text-blue-600"/>
					</a>
					<a rel="noopener noreferrer" href="https://www.instagram.com/mayapurtv/" title="Instagram" className="flex items-center p-1">
						<FaInstagramSquare   className="text-3xl text-pink-500"/>
					</a>
					<a rel="noopener noreferrer" href="https://www.youtube.com/@MayapurTVOfficial" target="_blank" title="Youtube" className="flex items-center p-1">
						<FaYoutube  className="text-3xl text-red-600"/>
					</a>
				</div>
			</div>
			<div className="space-y-3">
				<div className="flex justify-start space-x-3">
					<a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.nativevidio&hl=en_IN&gl=US" title="Apps" className="flex items-center p-1">
						<img src={playstore} alt="" className="w-32" />
					</a>
					
				</div>
			</div>
		</div>
	</div>
	<div className="py-6 text-sm text-center text-gray-600">© মায়াপুর সংবাদ {currentYear} </div>
</footer>
        </div>
    );
};

export default Footer;