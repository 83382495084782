/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./tv.css";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import live from "../../../assets/image/baneer/live.png";
const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const Tv = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [video, setVideo] = useState([]);

  useEffect(() => {
    socket.emit("retrieveAllAppData");
    socket.on("allAppDataRetrieved", (data) => {
      setVideo(data);
    });
  }, []);

  const api = video.slice(0, 2);

  return (
    <div>
      {api?.map((item, index) => {
        const title = item.title;
        const id = item._id;
        if (id === "app1" && title === "True") {
          const { url } = item;

          console.log(url);
          return (
            <div
              key={index}
              className="relative max-w-full xl:max-w-[1088px] xl:max-h-[650px] lg:max-h-[550px] md:max-h-[500px] mx-auto my-5"
            >
              <ReactPlayer
                url={url}
                controls={true} // Keep controls to enable play/pause button
                className="react-player" // Add a custom class for styling
                width="100%" // Set the width of the player
                height="100%" // Set the height of the player
                playing={true}
              />
              <div>
                <img
                  src={live}
                  alt=""
                  className="rounded-lg w-28 h-8 absolute top-5 left-5"
                />
              </div>
            </div>
          );
        } else if (id === "app2" && title === "True") {
          const { url } = item;

          // console.log(lastPart);
          return (
            <div
              key={index + 1}
              className="max-w-full xl:max-w-[1088px] xl:max-h-[650px] lg:max-h-[550px] md:max-h-[500px] min-h-[500px] mx-auto my-8"
            >
              {/* youtube */}
              <ReactPlayer
                url={url}
                controls={true} // Keep controls to enable play/pause button
                className="react-player" // Add a custom class for styling
                width="100%" // Set the width of the player
                height="500px" // Set the height of the player
              />
            
            </div>
          );
        }
      })}
    </div>
  );
};

export default Tv;
